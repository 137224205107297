/* eslint-disable no-undef */
import React, { Component } from 'react';
import './CookiePolicyDialog.scss';

class CookiePolicyDialog extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.async = true;
    script.src = '/assets/cookie-policy.js';
    this.instance.appendChild(script);
  }

  render() {
    return (
      <div ref={(el) => { this.instance = el; }} />
    );
  }
}

export default CookiePolicyDialog;
