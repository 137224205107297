import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// eslint-disable-next-line react/prop-types
const LazyImage = ({
  alt,
  title,
  height,
  src,
  width,
}) => (
  <Fragment>
    <LazyLoadImage
      alt={alt}
      title={title}
      height={height}
      src={src}
      width={width}
    />
  </Fragment>
);

LazyImage.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
};

LazyImage.defaultProps = {
  alt: '',
  title: '',
  height: '',
  width: '',
};

export default LazyImage;
