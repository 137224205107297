import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import NotifyWidgetPopup from '../NotifyWidgetPopup';
import Header from '../Header';
import Footer from '../Footer';
import CookiePolicyDialog from '../CookiePolicyDialog';
import PayInTwoNotify from '../PayInTwoNotify';

const Layout = ({ children, className }) => (
  <div>
    <PayInTwoNotify />
    <div className={classNames('main-wrapper', className)}>
      <Header />
      {children}
      <Footer />
      <CookiePolicyDialog />
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Layout.defaultProps = {
  className: '',
};

export default Layout;
