import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SectionHeading = ({
  heading,
  subHeading,
  className,
}) => (
  <Fragment>
    <h2
      className={classNames(
        'Section__heading',
        className,
      )}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: heading }}
    />
    {subHeading && (
      <div
        className={classNames(
          'Section__description',
          className,
        )}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: subHeading }}
      />
    )}
  </Fragment>
);

SectionHeading.propTypes = {
  heading: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  subHeading: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

SectionHeading.defaultProps = {
  className: undefined,
  subHeading: undefined,
};

export default SectionHeading;
