// @todo enable next eslint rules
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable */
import React, { Component } from 'react';
import classNames from 'classnames';
import logo from '../../../static/images/logo.svg';

class Header extends Component {
  state = {
    openMenu: {
      main: false,
    },
    scroll: false,
  };

  componentDidMount() {
    window.onscroll = () => this.handleScroll();
  }

  handleScroll = () => {
    const windowWidth = window.innerWidth <= 1024 ? 0 : 95;
    this.setState({
      scroll: window.scrollY > windowWidth,
    });
  };

  toggleMenu = (menuName = 'main') => {
    const windowWidth = window.innerWidth <= 1024;
    this.setState(
      ({ openMenu }) => ({ openMenu: {
          ...openMenu,
          [menuName]: !openMenu[menuName] && windowWidth
        }}),
      () => {
        const { openMenu } = this.state;
        if (openMenu[menuName] && windowWidth) {
          document.getElementsByTagName('html')[0].classList.add('overflow');
          document.getElementsByTagName('body')[0].classList.add('menu--open');
        } else {
          document.getElementsByTagName('html')[0].classList.remove('overflow');
          document.getElementsByTagName('body')[0].classList.remove('menu--open');
        }
      },
    );
  };

  render() {
    const { openMenu, scroll } = this.state;
    return (
      <header className={classNames(
        'header header--position-sticky',
        scroll && 'header--sticky',
        openMenu && 'show menu-open',
      )}>
        <div
          onClick={() => this.toggleMenu()}
        />
          <div className="container">
            <a href="/" className="logo"><img src={logo} alt="CA.CustomWritings.com" width="273" height="52" /></a>
            <div
              onClick={() => this.toggleMenu()}
              data-action="toggle-menu"
              className={classNames(
                'header__toggle-menu',
                openMenu.main && 'header__toggle-menu--active',
              )}
              aria-label="toggle menu"><span/></div>
            <div
              className={classNames(
                'header__menu-wrapper',
                openMenu.main && 'header__menu-wrapper--open',
              )}
            >
              <ul className="header__menu">
                <li className="header__menu__item"><a href="https://www.customwritings.com/pricing.html" rel="nofollow">Pricing</a></li>
                <li className="header__menu__item"><a href="https://www.customwritings.com/essay-writer.html" rel="nofollow">Hire writers</a></li>
                <li className="header__menu__item"><a href="https://www.customwritings.com/our-essay-samples.html" rel="nofollow">Samples</a>
                </li>
                <li className="header__menu__item"><a href="https://www.customwritings.com/contact-us.html" rel="nofollow">Contact
                  us</a></li>
              </ul>
              <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Order now</a>
            </div>
            <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button button--order-desktop">Order now</a>
          </div>
      </header>
    );
  }
}

export default Header;

/* eslint-enable */
